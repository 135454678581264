<template>
  <v-card elevation="0" class="pa-0">
    <v-card-title>
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(itemTab, index) in listTab"
          :key="index"
          class="primary--text pl-5 pr-5"
          :href="`#${itemTab.component}`"
        >
          <span class="text-h6">{{ itemTab.text }}</span>
        </v-tab>
      </v-tabs>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(itemTab, index) in listTab"
          :key="index"
          :value="itemTab.component"
        >
          <v-card flat>
            <v-card-text>
              <component :is="itemTab.component"></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import GlassPrescriptionTreatment from "./Optometrist/GlassPrescription";
import ClsPrescription from "./Optometrist/CLsPrescription";
import i18n from "@/plugins/i18n";

export default {
  name: "TreatmentPlanPage",
  components: {
    GlassPrescriptionTreatment,
    ClsPrescription,
  },
  data: () => ({
    namePath: "",
    listTab: [
      {
        text: i18n.t("examination.treatmentPlan.glassPrescription"),
        component: "GlassPrescriptionTreatment",
      },
      {
        text: i18n.t("examination.treatmentPlan.clsPrescription"),
        component: "ClsPrescription",
      },
    ],
    tab: null,
  }),
};
</script>

<style>
</style>