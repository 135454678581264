<template>
  <v-sheet elevation="0">
    <div class="d-block pa-1">
      <v-row class="pa-0">
        <!-- New Glass Prescription -->
        <v-col cols="12">
          <v-sheet elevation="0">
            <div class="d-block pa-1">
              <v-row class="pa-0" justify="space-between">
                <!-- SPH, CYL, AXS, BCVA, ADD, NVA -->
                <v-col lg="6" md="7" sx="12" sm="12">
                  <!-- Type glass prescription -->
                  <v-row>
                    <v-col cols="12">
                      <div class="d-block w-100 mt-2">
                        <GTComponent
                          :pathPrefix="pathPrefix"
                          :dataPoints="dataPoints"
                          v-model="glassType"
                        />
                        <GSComponent
                          :pathPrefix="pathPrefix"
                          :dataPoints="dataPoints"
                          v-model="glassStatus"
                        />
                        <div
                          class="d-block pa-0"
                          v-if="glassType.val == 'Progressive' && showExamRx"
                        >
                          <PGTComponent
                            :pathPrefix="pathPrefix"
                            :dataPoints="dataPoints"
                          />
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <template v-if="showExamRx">
                    <v-row>
                      <v-col cols="2"></v-col>
                      <v-col cols="5" class="text-center font-weight-bold">
                        <span>OD</span>
                      </v-col>
                      <v-col cols="5" class="text-center font-weight-bold">
                        <span>OS</span>
                      </v-col>
                    </v-row>
                    <div v-for="component in components" :key="component">
                      <component
                        :is="component"
                        :pathPrefix="pathPrefix"
                        :dataPoints="dataPoints"
                      ></component>
                    </div>
                    <!-- Take note -->
                    <v-row>
                      <v-col cols="12">
                        <div class="d-block w-100">
                          <span class="font-weight-bold w-100"
                            >Take Note | Lưu ý</span
                          >
                          <QNGPComponent
                            :pathPrefix="pathPrefix"
                            :dataPoints="dataPoints"
                          />
                          <NGPComponent
                            :pathPrefix="pathPrefix"
                            :dataPoints="dataPoints"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <!--Alert if not having a GP Record  -->
                    <div
                      class="d-flex w-100 align-center mt-1 justify-center"
                      v-if="!havingRecordGP"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="#ffc107"> mdi-alert-box </v-icon>
                        <span class="ml-1 amber--text text-h6">
                          Please save to create new glass data before printing!
                        </span>
                      </div>
                    </div>
                  </template>
                  
                </v-col>
                <!-- Type Glass - progressive type -->
                <v-col lg="4" md="5" sx="12" sm="12">
                  <div class="d-flex flex-column pt-5">
                    <div class="mt-5 subjectiveResult">
                      <table class="subjectiveConclusion">
                        <caption class="text-subtitle-1 font-weight-bold mt-3">
                          Subjective Refraction
                        </caption>
                        <thead>
                          <th></th>
                          <td class="font-weight-bold text-center">OD</td>
                          <td class="font-weight-bold text-center">OS</td>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in listSubjective"
                            :key="index"
                          >
                            <template v-if="item != 'bcva'">
                              <th class="text-left">
                                {{ item.toUpperCase() }}
                              </th>
                              <td class="text-center">
                                {{ optometryConclusion.od.subjective[item] }}
                              </td>
                              <td class="text-center">
                                {{ optometryConclusion.os.subjective[item] }}
                              </td>
                            </template>
                            <template v-else>
                              <th class="text-left">
                                {{ item.toUpperCase() }}
                              </th>
                              <td class="text-center" colspan="2">
                                {{ optometryConclusion.ou.subjective[item] }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- Action for glass prescription -->
      <v-row justify="end">
        <v-col cols="4"></v-col>
        <v-col lg="2" md="4" sx="12" sm="12">
          <div class="d-flex pa-2 align-center">
            <v-btn
              color="#2196f3"
              @click="saveDataPoint"
              class="ma-1 white--text"
              style="width: 50%"
            >
              {{ $t("common.Save") }}
              <v-icon small class="ml-2"> mdi-content-save </v-icon>
            </v-btn>
            <!-- <v-btn 
              color="#607d8b"
              @click="openGlassPrint"
              class="ma-1 white--text"
              style="width: 50%"
              >
              Print 
              <v-icon small class="ml-2">
                mdi-printer
              </v-icon>
            </v-btn> -->
            <GeneralPrint
              :childrensMenu="childrensMenu"
              :optionPrint="OptionPrint.Horizontal"
              :patientInfo="this.patientInfo"
              :listWantToPrint="listWantToPrint"
              :justIcon="justIcon"
            >
              <template #btnPrint>
                <v-btn
                  color="#607d8b"
                  class="ma-1 white--text"
                  style="width: 100%"
                >
                  {{ $t("common.print") }}
                  <v-icon small class="ml-2"> mdi-printer </v-icon>
                </v-btn>
              </template>
            </GeneralPrint>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-4"></v-divider>
      <v-row>
        <v-col cols="12">
          <MedicationEpisodeRecordComponent
            :episodeRecordID="episodeRecordID"
          />
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import DataPointService from "@/services/dataPoint";
import OptometryConclusionService from "@/services/optometryConclusion";
import MedicationEpisodeRecordComponent from "@/components/common/Medication/MedicationContainer";
import GeneralPrint from "@/components/prescription/GeneralPrint";
import { OptionPrint } from "@/plugins/constant";

export default {
  name: "TreatmentGlassPrescriptionComponent",
  components: {
    MedicationEpisodeRecordComponent,
    GeneralPrint,
  },
  watch: {
    glassStatus() {
      if (this.glassStatus != null) {
      
        let listVal = this.glassStatus.split("|"); // no glass|no glass, new glass, old glass
        this.showExamRx = (listVal[0] == "Old glass" || listVal[0] == "No glass") ? false : true;
        console.log(listVal[0])
        if (listVal[0] != "New glass") {
          this.dataPoints = _.filter(_.cloneDeep(this.dataPoints), (item) => {
            if (item.path.includes("/GP/GS/OU") || item.path.includes("/GP/GT/OU")) {
              return item;
            } else if (!item.path.includes("/GP")) {
              return item;
            }
          });
        } else {
          // filter data point without /GP/OS/OU 
          this.dataPoints = _.concat(this.dataPoints, this.GPDataPoint);
        }
        
      }
    }
  },
  data: () => ({
    OptionPrint,
    patientInfo: {
      episodeRecordID: "",
      patientID: "",
    },
    childrensMenu: false,
    justIcon: true,
    listWantToPrint: ["rimmed-glass"],
    openPrintPrescription: false,
    episodeRecordID: null,
    pathPrefix: "/GP",
    glassType: {},
    dataPoints: [],
    glassStatus: null,
    showExamRx: true,
    components: [
      "SPHComponent",
      "CYLComponent",
      "AXSComponent",
      "DSVAComponent",
      "BCVAComponent",
      "ADDComponent",
      "NVComponent",
    ],
    optometryConclusion: {
      od: {
        subjective: {
          add: "",
          addToNVA: "",
          bcva: "",
          nva: "",
          refractiveError: "",
          rx: "plano",
          va: "",
        },
      },
      os: {
        subjective: {
          add: "",
          addToNVA: "",
          bcva: "",
          nva: "",
          refractiveError: "",
          rx: "plano",
          va: "",
        },
      },
      ou: {
        subjective: {
          add: "",
          addToNVA: "",
          bcva: "",
          nva: "",
          refractiveError: "",
          rx: "plano",
          va: "",
        },
      },
    },

    listSubjective: ["rx", "va", "bcva", "add", "nva", "addToNVA"],
    havingRecordGP: false,
    GPDataPoint: []
  }),
  created() {
    this.patientInfo.episodeRecordID = this.$route.params.episodeRecordID;
    this.patientInfo.patientID = this.$route.params.patientId;
    this.episodeRecordID = this.$route.params.episodeRecordID;

    this.getDataPoint();
    this.loadSubjective();
  },

  methods: {
    async getDataPoint() {
      var result = await DataPointService.searchDataPoint(this.episodeRecordID);
      if (result.error) {
        this.$toast.error("Can not get data!");
        return;
      }
      // parse data
      this.dataPoints = result.items;
      if (!this.checkNGDataPoint()) {
        this.havingRecordGP = false;
        
        this.cloneGPDataFromSJR();     
      } else {
        this.havingRecordGP = true;
        if (!this.checkGPRxData()) {
          console.log('ko co')
          this.cloneGPDataFromSJR();
        } else {
          this.getGPRxDataPoint()
        }
        
      }
    },
    async saveDataPoint() {
      var patientID = this.$route.params.patientId;
      var result = await DataPointService.postCollection(
        this.episodeRecordID,
        patientID,
        this.dataPoints
      );
      if (result.error) {
        this.$toast.error("Can not save Data!");
        return;
      }
      this.$toast.success("Save new glass Prescription successfully!");
      this.havingRecordGP = true;
    },
    async loadSubjective() {
      var result = await OptometryConclusionService.getElementConclusion(
        this.episodeRecordID,
        "Subjective"
      );
      if (result.error) {
        this.$toast.error("Can not get a Subjective Result");
        return;
      }

      // parse data
      this.optometryConclusion = {
        od: { subjective: result.od.subjective },
        os: { subjective: result.os.subjective },
        ou: { subjective: result.ou.subjective },
      };
    },
    // openGlassPrint(){
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/glass`;
    //   window.open(srcLinkPrint, "Glass Prescription", "width=800, height=500")
    // },
    checkNGDataPoint() {
      // check that having data of New Glass Prescription
      let regex = /^\/GP\/.*/;

      let GPDataPoint = _.filter(this.dataPoints, (item) =>
        regex.test(item.path)
      );
      if (GPDataPoint.length > 0) {
        return true;
      }
      return false;
    },
    checkGPRxData() {
      let listGPWithoutStatusTypeProgress = _.cloneDeep(this.dataPoints);
      let filter = listGPWithoutStatusTypeProgress.filter(i => 
                      /^\/GP\/.*/.test(i.path) 
                      && !i.path.includes("/GP/GS/OU") 
                      && !i.path.includes("/GP/GT/OU") 
                      && !i.path.includes("/GP/PGT/OU"));
      return filter.length > 0;
    },
    cloneGPDataFromSJR() {
      // filter SJR dataPoint => change path SJR -> GP
      let regex = /^\/SJR\/.*/;
      let GPDataPoint = _.filter(_.cloneDeep(this.dataPoints), (item) =>
        regex.test(item.path)
      ).map((item) => {
        item.path = item.path.replace("/SJR", "/GP");
        return item;
      });
      this.dataPoints = _.concat(this.dataPoints, GPDataPoint);
      this.GPDataPoint = GPDataPoint; // store GP DataPoint to do something business with GlassStatus
    },
    getGPRxDataPoint() {
      let GPDataPoint = _.filter(_.cloneDeep(this.dataPoints), (item) => 
                              item.path.includes("/GP") 
                              && !item.path.includes("/GP/GS/OU")  
                              && !item.path.includes("/GP/PGT/OU"));
      // store gp data 
      this.GPDataPoint = GPDataPoint;
    }
     
  },
};
</script>

<style scoped lang="scss">
.subjectiveResult {
  background: #f5f5f5;
  border: 1px solid #e2e5e9;
  padding: 5px;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}
.subjectiveConclusion {
  width: 100%;
  padding: 5px;

  th,
  td,
  tr {
    padding: 2px;
  }
  tr {
    margin-top: 3px;
  }
  .text-center {
    text-align: center;
  }
}
</style>