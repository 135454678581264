<template>
  <v-card elevation="0">
    <v-card-text class="pa-1">
      <!-- block tab cls -->
      <v-row>
        <v-col lg="4" md="5" sx="12" sm="12">
          <v-tabs fixed-tabs v-model="clsTab">
            <v-tab
              v-for="(itemCls, index) in clsTypes"
              :key="index"
              :href="`#${itemCls.text}`"
            >
              <v-badge v-if="itemCls.hasValue" dot color="green">
                <span class="text-subtitle-2 font-weight-bold">{{
                  itemCls.text
                }}</span>
              </v-badge>
              <span class="font-weight-bold" v-else>{{ itemCls.text }}</span>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <!-- block content tab -->
      <div class="d-block">
        <v-tabs-items v-model="clsTab">
          <v-tab-item
            v-for="(itemCls, index) in clsTypes"
            :key="index"
            :value="itemCls.text"
          >
            <v-card>
              <v-row>
                <v-col cols="12">
                  <div class="d-block w-100 mt-4">
                    <CSComponent
                      :pathPrefix="`/CLS/${itemCls.typePath}/${itemCls.orderTypePath}`"
                      :dataPoints="dataPoints"
                    />
                  </div>
                </v-col>
              </v-row>
              <!-- pathPrefix: /CLS/CLSTH/OHCLS -->
              <v-card-text>
                <ClsCommonOrder
                  :clsType="itemCls.description"
                  :pathPrefix="`/CLS/${itemCls.typePath}/${itemCls.orderTypePath}`"
                  :dataPoints="dataPoints"
                />
                <v-row class="pa-0">
                  <v-col lg="8" md="10" sx="12" sm="12" class="pl-3 pr-3">
                    <div class="d-block pl-3 pr-3">
                      <CLSONComponent
                        :dataPoints="dataPoints"
                        :pathPrefix="`/CLS/${itemCls.typePath}/${itemCls.orderTypePath}`"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="8" md="10" sx="12" sm="12">
                    <div class="d-flex justify-end pr-3 align-center">
                      <v-btn
                        color="#2196f3"
                        @click="saveDataPoint(itemCls.type)"
                        class="pa-4 pl-5 pr-5 ma-1 white--text"
                      >
                        {{ $t("common.Save") }}
                        <v-icon small class="ml-2"> mdi-content-save </v-icon>
                      </v-btn>
                      <!-- <v-btn 
                        color="#607d8b"
                        class="ma-1 white--text"
                        @click="printClsPrescription(itemCls.type)">
                        Print 
                        <v-icon small>
                          mdi-printer
                        </v-icon>
                      </v-btn> -->
                      <GeneralPrint
                        :childrensMenu="childrensMenu"
                        :optionPrint="OptionPrint.Horizontal"
                        :patientInfo="patientInfo"
                        :listWantToPrint="wantToPrint(itemCls.type)"
                        :justIcon="justIcon"
                      >
                        <template #btnPrint>
                          <v-btn
                            color="#607d8b"
                            class="ma-1 white--text"
                            style="width: 100%"
                          >
                            {{ $t("common.print") }}

                            <v-icon small class="ml-2"> mdi-printer </v-icon>
                          </v-btn>
                        </template>
                      </GeneralPrint>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <!-- Action -->

      <v-divider class="mt-4 mb-4"></v-divider>

      <!-- Medication -->
      <v-row>
        <v-col cols="12">
          <MedicationEpisodeRecordComponent
            :episodeRecordID="episodeRecordID"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import ClsCommonOrder from "@/components/common/ClsOrder/ClsCommonOrder";
import DataPointService from "@/services/dataPoint";
import MedicationEpisodeRecordComponent from "@/components/common/Medication/MedicationContainer";
import GeneralPrint from "@/components/prescription/GeneralPrint";
import { OptionPrint } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  name: "ClsPrescriptionComponent",
  components: {
    ClsCommonOrder,
    MedicationEpisodeRecordComponent,
    GeneralPrint,
  },
  data: () => ({
    OptionPrint,
    patientInfo: {
      episodeRecordID: "",
      patientID: "",
    },
    childrensMenu: false,
    justIcon: true,
    clsTypes: [
      {
        text: i18n.t("examination.treatmentPlan.soft"),
        description: "Soft Order",
        hasValue: false,
        trialPrefix: `/CLS/CLSTS`,
        typePath: "CLSTS",
        trialTypePath: "TSCLS",
        orderTypePath: "OSCLS",
        type: "soft",
      },
      {
        text: i18n.t("examination.treatmentPlan.Hard"),
        description: "Hard Order",
        hasValue: false,
        trialPrefix: `/CLS/CLSTH`,
        typePath: "CLSTH",
        trialTypePath: "THCLS",
        orderTypePath: "OHCLS",
        type: "hard",
      },
      {
        text: "Ortho-K",
        description: "Ortho-K Order",
        hasValue: false,
        trialPrefix: `/CLS/CLSTO`,
        typePath: "CLSTO",
        trialTypePath: "TOCLS",
        orderTypePath: "OOCLS",
        type: "orthok",
      },
    ],
    clsTab: "Soft",
    episodeRecordID: null,
    dataPoints: [],
    clsDataPoint: [],
    plusValue: ["S", "C", "A"],
  }),
  created() {
    this.patientInfo.episodeRecordID = this.$route.params.episodeRecordID;
    this.patientInfo.patientID = this.$route.params.patientId;

    this.episodeRecordID = this.$route.params.episodeRecordID;
    this.getDataPoint();
  },
  methods: {
    wantToPrint(type) {
      var arr = [];
      arr.push(`${type}-cls-glass`);
      return arr;
    },
    async getDataPoint() {
      var result = await DataPointService.searchDataPoint(this.episodeRecordID);
      if (result.error) {
        this.$toast.error("Can not get data!");
        return;
      }
      // parse data
      this.dataPoints = result.items;
      //
      this.clsDataPoint = this.dataPoints.filter(function (i) {
        return i.path.includes("/CLS/");
      });
      //
      let listPathCls = this.clsDataPoint.map((i) => i.path);

      this.clsTypes.forEach((i) => {
        if (
          !this.checkHasOrderCls(this.clsDataPoint, i.typePath, i.orderTypePath)
        ) {
          // if can not find the order cls by type Hard or Soft or OrthoK
          if (this.checkHasTrialCls(this.clsDataPoint, i.trialPrefix)) {
            i.hasValue = true; // use for trial and order to announce the user that this tab has a value
          }
          let maxCount = this.getLastTrial(listPathCls, i.trialPrefix); // max Trial for Hard or Soft or OrthoK
          if (maxCount > 0) {
            this.convertTrialToOrder(
              i.trialTypePath,
              i.trialPrefix,
              maxCount,
              i.typePath,
              i.orderTypePath
            );
          }
        } else {
          i.hasValue = true; // use for trial and order to announce the user that this tab has a value
        }
      });
    },
    checkHasOrderCls(clsDataPoint, typePath, orderTypePath) {
      let orderPath = `/CLS/${typePath}/${orderTypePath}/`;
      let findOrderPath = clsDataPoint.find((i) => i.path.includes(orderPath));
      return findOrderPath != null;
    },
    checkHasTrialCls(clsDataPoint, pathCheck) {
      let findByPath = clsDataPoint.find((i) => i.path.includes(pathCheck));
      return findByPath != null;
    },

    getLastTrial(listPathFromClsDatapoint, path, maxCount = 0) {
      // TRIAL-1
      let pathQuery = `${path}/TRIAL-${maxCount + 1}`; // /CLS/CLSTH/TRIAL-1
      if (listPathFromClsDatapoint.length == 0) {
        return maxCount;
      }
      let filterTrialTime = listPathFromClsDatapoint.filter((i) =>
        i.includes(pathQuery)
      );
      if (filterTrialTime.length == 0) {
        return maxCount;
      } else {
        let filterNextTrialTime = listPathFromClsDatapoint.filter(
          (i) => !i.includes(pathQuery)
        ); // filter to decrease size of array
        return this.getLastTrial(filterNextTrialTime, path, maxCount + 1);
      }
    },

    convertTrialToOrder(
      trialTypePath,
      trialPath,
      maxCount,
      typePath,
      orderTypePath
    ) {
      // TD: Trial Detail --- only take care TD
      let pathQuery = `${trialPath}/TRIAL-${maxCount}/${trialTypePath}`; // /CLS/CLSTH/TRIAL-1/THCLS/
      let lastTrial = _.cloneDeep(
        this.clsDataPoint.filter((i) => i.path.includes(`${pathQuery}/TD/`))
      );

      let regex = /(\/CLS).*(\/TD)(\/(B|BC|DIA|S|C|A|TYPE))(\/(OD|OS))/;

      let orderCLS = lastTrial.map((i) => {
        // format path
        i.path = i.path.replace(regex, `$1/${typePath}/${orderTypePath}$2$3$5`);
        i.value = this.overRefAndTrialDetail(i, pathQuery) ?? i.value;
        return i;
      });

      this.dataPoints = _.concat(this.dataPoints, _.cloneDeep(orderCLS));
      // from these - we clone another data for order of type Cls from the last trial of type
    },

    overRefAndTrialDetail(item, pathQuery) {
      let dataPoint = _.cloneDeep(item);
      let regex = /.*(\/TD)(\/(S|C|A|))(\/(OD|OS))/;

      if (regex.test(dataPoint.path)) {
        let pathFix = dataPoint.path.replace(regex, `$3$4`); // S/OD | C/OD

        let sideEye = pathFix.split("/").pop(); // last element of array
        let itemPlus = pathFix.split("/").shift(); // S|C|A
        let sideEyes = ["OD", "OS"];
        if (sideEyes.includes(sideEye)) {
          // overRef path
          let pathOVR = "OVR";
          let overPath = `${pathQuery}/${pathOVR}/${itemPlus}/${sideEye}`;
          let findOverPath = _.cloneDeep(
            this.dataPoints.find((dtP) => dtP.path == overPath)
          );
          if (typeof findOverPath != "undefined") {
            let valFindOverPath = findOverPath.value;
            let value = this.SCAPlusTrialWithOrder(item.value, valFindOverPath);
            let x = Math.ceil(value * 1000) / 1000;
            return parseFloat(x).toString();
          } else {
            return dataPoint.value.toString();
          }
        }
      } else {
        return null;
      }
    },

    SCAPlusTrialWithOrder(trialVal, overValue) {
      let trVal = parseFloat(trialVal) || 0;
      let ovVal = parseFloat(overValue) || 0;
      return trVal + ovVal;
    },

    async saveDataPoint(typeCls) {
      let patientID = this.$route.params.patientId;
      var result = await DataPointService.postCollection(
        this.episodeRecordID,
        patientID,
        this.dataPoints
      );
      if (result.error) {
        this.$toast.error(result.message);
        return;
      }
      this.$toast.success("Save Cls Prescription successfully!");
      this.clsTypes = this.clsTypes.map((i) => {
        if (i.type == typeCls) {
          i.hasValue = true;
        }
        return i;
      });
    },

    // printClsPrescription(typeCls) {
    //   console.log(typeCls)
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/cls/${typeCls}`;
    //   window.open(srcLinkPrint, "Cls Prescription", "width=800, height=500")
    // }
  },
};
</script>

<style>
</style>